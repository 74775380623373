<template>
  <div id="body">
    <div class="nav">
      <ul>
        <router-link :to="{ name: 'BasicSetting' }" tag="li" activeClass="active">基本设置</router-link>
        <router-link :to="{ name: 'safetySet' }" tag="li" activeClass="active">安全设置</router-link>
        <!-- <router-link :to="{ name: 'accountBind' }" tag="li" activeClass="active">账号绑定</router-link> -->
      </ul>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
#body{
    background: white;
    display: flex;
}
.nav {
     width: 228px;
    height: 743px;
    // background: aquamarine;
    border-right: 1px solid #cccccc;
  ul {
    list-style: none;
    li{
        text-align: left;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        line-height: 45px;
        padding-left: 30px;
        // color: #000000;
    }
    li:hover{
        cursor: pointer;
    }
  }
}
.active{
    color:#1890FF;
    font-weight: 600;
    border-right:3px solid#0F90FF;
   background: #E7F7FF;
  }
</style>
